import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { FaRegCheckCircle } from 'react-icons/fa';

export const Experience = () => {
    const formInitialDetails = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    }
    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Send');
    const [status, setStatus] = useState({});

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonText("Sending...");
        let response = await fetch("http://localhost:5000/contact", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(formDetails),
        });
        setButtonText("Send");
        let result = await response.json();
        setFormDetails(formInitialDetails);
        if (result.code == 200) {
            setStatus({ succes: true, message: 'Message sent successfully' });
        } else {
            setStatus({ succes: false, message: 'Something went wrong, please try again later.' });
        }
    };

    return (
        <section className="contact experience-bg" id="experience">
            <Container>
                <Row className="align-items-center">
                    {/* <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col> */}
                    {/* <Col size={12} md={6}> */}
                    <div className="experience-section" id="experience">
                        
                        <Row>
                        <h2 className="text-center">Work Experience</h2>
                        <div className="white-line"></div>
                            {/* Frontend Developer - Leader IT */}
                            <Col md={6} className="experience-item">
                                <h3>Frontend Developer - Leader IT</h3>
                                <p>May 2022 - Present</p>
                                <ul>
                                    <li><FaRegCheckCircle /> Developed & optimized user experiences & integrating of RESTful API, resulting in a 30% reduction in load time.</li>
                                    {/* <li><FaRegCheckCircle /> Streamlined collaboration with UI/UX designers and backend developers.</li> */}
                                    <li><FaRegCheckCircle /> Implemented Agile methodologies, conducted code reviews, facilitated QA/UAT phases, ensuring reliable code, efficient management.</li>
                                </ul>
                            </Col>

                            {/* Senior System Engineer - Infosys */}
                            <Col md={6} className="experience-item">
                                <h3>Senior System Engineer - Infosys</h3>
                                <p>March 2021 - May 2022</p>
                                <ul>
                                    <li><FaRegCheckCircle /> Contributed to delivery of high-performance applications for clients.</li>
                                    <li><FaRegCheckCircle /> Optimized application architecture and enhanced user experience.</li>
                                </ul>
                            </Col>

                            {/* System Engineer - Infosys */}
                            <Col md={6} className="experience-item">
                                <h3>System Engineer - Infosys</h3>
                                <p>April 2020 - March 2021</p>
                                <ul>
                                    <li><FaRegCheckCircle /> Ensured quality assurance in Cisco project using automation and scripting.</li>
                                    <li><FaRegCheckCircle /> Managed defects, conducted root cause analysis, and implemented TDD and CI.</li>
                                </ul>
                            </Col>
                            {/* System Engineer Trainee - Infosys */}
                            <Col md={6} className="experience-item">
                                <h3>System Engineer Trainee - Infosys</h3>
                                <p>November 2019 - April 2020</p>
                                <ul>
                                    <li><FaRegCheckCircle /> Completed foundation training in various technologies.</li>
                                    <li><FaRegCheckCircle /> Trained as a Full-Stack Developer in Spring framework and other technologies.</li>
                                </ul>
                            </Col>
                         
                            <h2 className="text-center">Internships</h2>
                            <div className="white-line"></div>
                            {/* Internship - Edureka */}
                            <Col md={6} className="experience-item">
                                <h3>Technical Consultant Intern - Edureka</h3>
                                <p>Sept 2019 - Nov 2019</p>
                                <ul>
                                    <li><FaRegCheckCircle /> Tools: Power BI & Tableau</li>
                                    <li><FaRegCheckCircle /> Role: Data visualizations & analytics consultant.</li>
                                    {/* <li><FaRegCheckCircle /> Significantly contributed to client success, resulting in a 15% boost in client retention by providing effective technical guidance and support.</li> */}
                                </ul>
                            </Col>

                            {/* Internship - Srishti i2i Biz Solutions */}
                            <Col md={6} className="experience-item">
                                <h3>Web Development Intern - Srishti i2i Biz Solutions</h3>
                                <p>Jan 2018 - Feb 2018</p>
                                <ul>
                                    <li><FaRegCheckCircle /> Technology: Angular & Java Spring Boot</li>
                                    <li><FaRegCheckCircle /> Role: Full-Stack Developer</li>
                                    {/* <li><FaRegCheckCircle /> Collaboratively developed a Ticketing System-Single Page Application using Angular and Java Spring Boot, achieving a 15% reduction in issue resolution time through seamless integration and efficient teamwork.</li> */}
                                </ul>
                            </Col>

                            {/* Education Section */}
                            <h2 className="text-center">Education</h2>
                            <div className="white-line"></div>
                            <Col md={6} className="experience-item">
                                <h3 >Bachelor of Engineering (B.E)</h3>
                                <p >June 2019</p>
                                {/* <p >MVJ College Of Engineering</p> */}

                                <ul>
                                    <li><FaRegCheckCircle /> Computer Science & Engineering(CSE)</li>
                                    <li><FaRegCheckCircle /> Visvesvaraya Technological University(VTU), India</li>
                                </ul>
                            </Col>
                            <Col md={6} className="experience-item">
                                <h3 >Secondary School  & Intermediate</h3>
                                {/* <p >Gem International Residential School</p> */}
                                <p >April 2015</p>
                                <ul> 
                                <li><FaRegCheckCircle /> Secondary School - ICSE</li>
                                <li><FaRegCheckCircle /> Intermediate - Karnataka State Board</li>
                                   <li><FaRegCheckCircle /> Gem International Residential School, India</li>
                                </ul>
                            </Col>
                        </Row>
                    </div>

                    {/* <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Get In Touch</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.lasttName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>
                    {
                      status.message &&
                      <Col>
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col>
                    }
                  </Row>
                </form>
              </div>}
            </TrackVisibility> */}
                    {/* </Col> */}
                </Row>
            </Container>
        </section>
    )
}
