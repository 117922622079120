import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email
      })
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>Subscribe to our Newsletter<br /> & Never miss latest updates</h3>
            {/* Display a message indicating that the feature is still under construction */}
            <p className="construction-message">Oops! This feature is still under construction. Stay tuned for updates!</p>
          </Col>
          <Col md={6} xl={7}>
            {/* Display a disabled form to indicate that the feature is not yet available */}
            <form onSubmit={handleSubmit} disabled>
              <div className="new-email-bx">
                <input value={email} type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
              </div>
              <div className="subscribe-btn">
                <button type="submit" disabled>Submit</button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </Col>

  )
}
