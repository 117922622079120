import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/dentalbeing.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import rebatool from "../assets/img/rebatool.png";
import automax from "../assets/img/automax.png"; 
import taskm from "../assets/img/taskmanagement.jpg"; 
import blog2 from "../assets/img/blog2.png";
import recipe from "../assets/img/recipe.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Dental Startup",
      description: "Angular-Development",
      imgUrl: projImg1,
    },
    {
      title: "Automax2.0",
      description: "Angular-Development",
      imgUrl: automax,
    },
    {
      title: "Ticket Management System",
      description: "Angular-Development",
      imgUrl: taskm,
    },
    {
      title: "Improved REBA Tool",
      description: "OpenCV-Development",
      imgUrl: rebatool,
    }];

    const projects2=[
    {
      title: "Recipe Book",
      description: "Design & Development",
      imgUrl: recipe,
    },
    {
      title: "Performance Management System",
      description: "FullStack-Development",
      imgUrl: projImg2,
    },
    {
      title: "Technical Blog",
      description: "Wordpress",
      imgUrl: blog2,
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Projects are essential demonstrations of a developer's skills and capabilities, showcasing their expertise in various domains and their ability to solve real-world challenges.<br></br> <br></br>They serve as tangible evidence of a developer's achievements, innovations, and contributions to the tech community.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          projects2.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Coming..</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
